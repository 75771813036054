import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Hobbies = () => (
  <section className="pt-8 pb-16 bg-gray-50">
    <div className="container max-w-3xl px-3">
      <h2 className="uppercase text-center text-4xl mb-8">مشاريعي</h2>

      <div className="flex flex-col">
        {/** zero project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 mb-4 bg-white p-4">
            <div>
              <StaticImage
                src="../../images/projects/project-invoice-generator.png"
                alt="مشروع مولد المشاريع"
                placeholder="blurred"
                layout="fixed"
                className="mx-auto"
                width={300}
                // height={250}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl">Simple invoice generator</h3>
              <p>موقع لتوليد الفواتير وإدارتها.</p>
              <p>أنا عملت جانب البرمجة من جهة الخادوم backend.</p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">
                  Laravel, API, Generate PDF
                </span>
                .
              </p>
            </div>
          </div>
        </div>
        {/** ./zero project */}

        {/** first project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 mb-4 bg-white p-4">
            <div>
              <StaticImage
                src="../../images/projects/project-findme.png"
                alt="مشروع findme لإيجاد المفقودين"
                placeholder="blurred"
                layout="fixed"
                className="mx-auto"
                width={300}
                // height={250}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl">Findme</h3>
              <p>موقع للمساعدة في العثور على الأشخاص المفقودين.</p>
              <p>يمكن البحث عن المفقودين وإضافتهم مع بيانات عنهم.</p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">
                  React, S/CSS,Tailwind CSS, Jest, Firebase OAuth
                </span>
                .
              </p>
              <p>
                زيارة{' '}
                <a
                  href="https://friendly-galileo-978ccc.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  Findme
                </a>
                .
              </p>
              <p>
                الكود على{' '}
                <a
                  href="https://github.com/ReCoded-Org/capstone-project-findme"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  GitHub
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        {/** ./first project */}

        {/** second project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 md:items-center  mb-4 bg-white">
            <div>
              <StaticImage
                src="../../images/projects/project-WatheqBlogLogo.png"
                alt="مدونتي الشخصية"
                placeholder="blurred"
                layout="fixed"
                className="w-32 mx-auto"
                width={300}
                // height={100}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl mt-2">
                موقعي الشخصي
              </h3>
              <p>هو هذا الموقع الذي تتصفحونه.</p>
              <p>وفيه سيرتي المهنية ومدونتي.</p>
              <p>
                مر بعدة مراحل حيث كان مشروع PHP مع مكتبة Bootstrap CSS في
                الواجهة.
              </p>
              <p>
                التقنيات المستخدمة (سابقا):{' '}
                <span className="text-indigo-500">PHP, MySQL, Bootstrap</span>.
              </p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">
                  Gatsby, React, S/CSS, Tailwind CSS
                </span>
                .
              </p>
              <p>
                الكود السابق على{' '}
                <a
                  href="https://github.com/WatheqAlshowaiter/watheq.xyz"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  GitHub
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        {/** Fourth Project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 mb-4 bg-white p-4">
            <div>
              <StaticImage
                src="../../images/projects/content-style-guide-arabic.png"
                alt="صورة موقع دليل أسلوب كتابة المحتوى"
                placeholder="blurred"
                layout="fixed"
                className="w-32 mx-auto"
                width={300}
                height={250}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl mt-2">
                موقع دليل أسلوب كتابة المحتوى
              </h3>
              <p>
                نقل تصميم الموقع الأصلي من Next.js إلى Gatsby وتحويل الاتجاه من
                اليمين إلى اليسار (RTl)، وتعديل تصميم القالب الجديد بما يقارب
                الأصلي وما يخدم المشروع. بالإضافة إلى إدارة الموقع فيما يتعلّق
                بالسيرفر.
              </p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">
                  Gatsby, React, Netlify, CPanel, git/GitHub
                </span>
                .
              </p>
              <p>
                زيارة{' '}
                <a
                  href="https://csg.uxwritingar.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  الموقع
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        {/** third Project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 mb-4 bg-white p-4">
            <div>
              <StaticImage
                src="../../images/projects/homoudblog.png"
                alt="صورة من مدونة حمود الباهلي"
                placeholder="blurred"
                layout="fixed"
                className="w-32 mx-auto"
                width={300}
                height={250}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl mt-2">
                مدونة حمود الباهلي
              </h3>
              <p>
                تصميم مدونة في غاية البساطة والتركيز على سهولة القراءة بأبسط
                صورة ممكنة.
              </p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">
                  Gatsby, React, Netlify, Netlify CMS
                </span>
                .
              </p>
              <p>
                زيارة{' '}
                <a
                  href="https://homoudblog.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  المدونة
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        {/** Fifth Project */}
        <div className="w-full text-center mb-6 rounded-lg">
          <div className="flex flex-col md:flex-row md:mr-2 mb-4 bg-white p-4">
            <div>
              <StaticImage
                src="../../images/projects/project-samplePage2.png"
                alt="تحويل نموذج موقع إلى صفحة ويب مطابقة"
                placeholder="blurred"
                layout="fixed"
                className="w-32 mx-auto"
                width={300}
                height={250}
              />
            </div>
            <div className="text-right mr-6">
              <h3 className="text-center md:text-right text-2xl mt-2">
                تحويل نموذج موقع ثاني إلى صفحة ويب مطابقة
              </h3>
              <p>استخدمت فيها تقنيات الويب الأساسية دون مكاتب مساعدة</p>
              <p>والصفحة متجاوبة مع مختلف الأجهزة.</p>
              <p>
                التقنيات المستخدمة:{' '}
                <span className="text-indigo-500">HTML, CSS, JavaScript</span>.
              </p>
              <p>
                زيارة{' '}
                <a
                  href="https://watheqalshowaiter.github.io/sample_page2/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  صفحة المعاينة
                </a>
                .
              </p>
              <p>
                الكود على{' '}
                <a
                  href="https://github.com/WatheqAlshowaiter/sample_page2"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 hover:underline"
                >
                  GitHub
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Hobbies
