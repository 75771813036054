import React from 'react'

import './experience.css'

const Experience = () => (
  <section className="py-8 bg-gray-100">
    <div className="container max-w-6xl px-3">
      <h2 className="uppercase text-center text-4xl mb-8">الخبرة</h2>
      <div className="timeline-container relative">
        <div className="relative bg-white p-3 border-b shadow rounded-lg mr-8 mb-8">
          <div className="timeline-dot" />
          <div>
            <p className="m-0 mt-4 text-sm text-gray-700">
              2021 نوفمبر - الوقت الحالي
            </p>

            <h3 className="text-indigo-500">
              مطور ويب شامل (Full Stack) -{' '}
              <a
                href="http://smartmindsys.com/"
                target="_blank"
                rel="noreferrer"
              >
                Smart Mind for Systems
              </a>
            </h3>

            <p className="text-gray-700 leading-loose">
              <li>
                مسؤول عن الواجهة الخلفية (الموقع الفعلي أو واجهات برمجة
                التطبيقات) لمشاريع الشركة التي تستخدم إطار عمل لارافل.
              </li>
              <li>
                المشاركة في تصميم قاعدة البيانات حتى تصبح المشاريع جاهزة للبدء
                في التكويد.
              </li>
              <li>
                استخدام البرمجيات المتعلقة بلارافل: livewire وpassport وalpine
                وغيرها الكثير.
              </li>
            </p>
          </div>
        </div>
      </div>
      <div className="timeline-container relative">
        <div className="relative bg-white p-3 border-b shadow rounded-lg mr-8 mb-8">
          <div className="timeline-dot" />
          <div>
            <p className="m-0 mt-4 text-sm text-gray-700">
              أبريل 2021- مايو 2021
            </p>

            <h3 className="text-indigo-500">
              مطور واجهات الويب -{' '}
              <a
                href="https://www.linkedin.com/company/nestorx/"
                target="_blank"
                rel="noreferrer"
              >
                NestorX
              </a>
            </h3>

            <p className="text-gray-700 leading-loose">
              <li>
                استخدمت مكتبة React.js ومكتبة Material UI لبناء لوحة التحكم
                لنظام الإعدادات (Configuraiton System) الذي نبنيه.
              </li>
              <li>استخدمت نظام Git وموقع GitLab للتحكم بكل النسخ للكود.</li>
              <li>
                استخدمنا موقع Google Meet لكل التواصل المباشر لأن العمل كان عن
                بعد، وبرنامج Slack للتواصل النصي.
              </li>
              <li>
                كل التواصل كان باللغة الإنجليزية سواء كان نصيا أو مباشرا لأن
                الفريق كان متعدد الجنسيات، من اليمن وكندا والولايات المتحدة
                وباكستان.
              </li>
              <li>
                استخدمنا المنهجية الرشيقة (Agile) خاصة عبر إطار عمل Scrum بكل
                مراحله من الاجتماعات اليومية السريعة والاجتماعات الفصلية وغيرها.
              </li>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Experience
