import React from 'react'

const Education = () => (
  <section className="py-8 container max-w-6xl px-3">
    <h2 className="uppercase text-center text-4xl mb-8">التعليم</h2>

    <article className="mb-8 py-6 border-b flex flex-wrap md:flex-nowrap">
      <div className="flex-shrink-0">
        <div className="inline-block border-b pb-4">
          <div className="inline-block leading-none">
            <div className="text-gray-700 text-xs text-right">أبريل</div>
            <span className="font-bold text-3xl">2020</span>
          </div>

          <div className="inline-block font-bold mx-6">&ndash;</div>

          <div className="inline-block leading-none">
            <div className="text-gray-700 text-xs text-right">سبتمبر</div>
            <span className="font-bold text-3xl">2020</span>
          </div>
        </div>

        <p className="mt-4 md:text-right">Re:Coded</p>
      </div>

      <div className="md:mr-6 md:pr-6 md:border-r">
        <h3 className="text-indigo-500 text-2xl font-bold">
          مخيم تطوير واجهات الويب{' '}
        </h3>
        <p className="text-gray-700 leading-loose">
          <li>
            إكمال خمسة أشهر من مخيم برمجي مكثف، بما يعادل 400 ساعة دراسية تعلما
            مبنيا على المشاريع.
          </li>
          <li>تعلم أساسيات تطوير الواجهات: HTML و CSS و JavaScript.</li>
          <li>
            تعلم مكتبة React.js لبناء واجهات ويب: سريعة وجيدة التنظيم وحديثة.
          </li>
          <li>
            تعلم العمل الجماعي في قطاع التطوير البرمجي باستخدام Git/Github
            لإدارة الكود المصدري بكفاءة.
          </li>
          <li>
            تعلم تقنيات تطوير إضافية مثل: Firebase و Continues integration
            باستخدام CircleCI و Sass و Tailwind CSS و PostCSS و Jest JavaScript
            Testing Framework.
          </li>
          <li>
            تعلم بعض المهارات الشخصية مثل: إدارة الوقت، والتواصل الاحترافي،
            وإدارة المنجات بالمنهجية الرشيقة (Agile) باستخدام إطار عمل Scrum.
            وغيرها.
          </li>
          <li>
            تعلم مهارات العمل عن بعد باستخدم برامج اجتماعات الفيديو مثل برنامج
            Zoom وبرنامج Slack لإدارة الفريق والتواصل معه.
          </li>
        </p>
      </div>
    </article>

    <article className="mb-8 py-6 border-b flex flex-wrap md:flex-nowrap">
      <div className="flex-shrink-0">
        <div className="inline-block border-b pb-4">
          <div className="inline-block leading-none">
            <span className="font-bold text-3xl">2015</span>
          </div>

          <div className="inline-block font-bold mx-6">&ndash;</div>

          <div className="inline-block leading-none">
            <span className="font-bold text-3xl">2019</span>
          </div>
        </div>

        <p className="mt-4 md:text-right">جامعة صنعاء</p>
      </div>

      <div className="md:mr-6 md:pr-6 md:border-r">
        <h3 className="text-indigo-500 text-2xl font-bold">
          درجة البكالوريوس في تقنية المعلومات{' '}
        </h3>
        <h3 className="text-gray-700 leading-loose">
          {' '}
          مما درسته هذه المساقات:
        </h3>
        <div className="flex flex-col md:flex-row">
          <div className="text-gray-700 leading-loose w-full md:w-1/2 sm:px-8">
            <li> تنظيم الحاسوب</li>
            <li> البرمجة المرئية باستخدام #C</li>
            <li> أساسيات الشبكات</li>
            <li> أنظمة التشغيل</li>
            <li> أخلاقيات الحاسوب</li>
            <li> تحيل وتصميم الأنظمة</li>
            <li> نظم موزعة</li>
            <li> مهارات اتصال</li>
            <li> مناهج بحث</li>
            <li> البرمجة التكاملية</li>
            <li> أمن المعلومات</li>
          </div>
          <div className="text-gray-700 leading-loose w-full md:w-1/2 sm:px-8">
            <li>تطوير واجهات الويب </li>
            <li>تطوير النظم الخلفية للويب</li>
            <li> التجارة الإلكترونية</li>
            <li> تفاعل الإنسان مع الحاسوب</li>
            <li> هياكل البيانات والخوارزميات</li>
            <li> قواعد بيانات أساسية ومتقدمة</li>
            <li> مبادئ البرمجة الكائنية (OOP)</li>
            <li> هندسة البرمجيات</li>
            <li> الحوسبة المؤسسية</li>
          </div>
        </div>
      </div>
    </article>
  </section>
)

export default Education
