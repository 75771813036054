import React from 'react'

const Hobbies = () => (
  <section className="pt-8 pb-16 bg-gray-50">
    <div className="container max-w-3xl px-3">
      <h2 className="uppercase text-center text-4xl mb-8">اهتماماتي</h2>

      <div className="flex flex-wrap justify-around text-indigo-500">
        <div className="w-1/2  text-center mb-6">
          <div className="bg-white mb-4 inline-block p-8 rounded-full">
            <svg
              viewBox="0 0 62 50"
              className="hobby-icon fa-3x stroke-current text-indigo-500"
            >
              <g
                strokeWidth="1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g transform="translate(1.000000, 1.000000)" strokeWidth="2">
                  <path d="M0,0 L60,0 L60,12 L0,12 L0,0 Z M0,12 L0,48 L60,48 L60,12 L0,12 Z"></path>
                  <path d="M36,22 L43,30 L36,38 M24,22 L17,30 L24,38"></path>
                  <circle cx="6" cy="6" r="1"></circle>
                  <circle cx="13" cy="6" r="1"></circle>
                  <circle cx="20" cy="6" r="1"></circle>
                </g>
              </g>
            </svg>
          </div>

          <p className="text-sm font-bold m-0">التطوير</p>
        </div>

        <div className="w-1/2  text-center mb-6">
          <div className="bg-white mb-4 inline-block p-8 rounded-full">
            <svg
              viewBox="0 0 62 44"
              className="hobby-icon fa-3x stroke-current text-indigo-500"
            >
              <g
                strokeWidth="1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g transform="translate(1.000000, 1.000000)" strokeWidth="2">
                  <path d="M4,34 L4,2 C4,0.8954305 4.8954305,0 6,0 L54,0 C55.1045695,0 56,0.8954305 56,2 L56,34"></path>
                  <path d="M36,34 L36,36 L24,36 L24,34 L0,34 L0,40 C0,41.1045695 0.8954305,42 2,42 L58,42 C59.1045695,42 60,41.1045695 60,40 L60,34 L36,34 Z"></path>
                </g>
              </g>
            </svg>
          </div>

          <p className="text-sm font-bold m-0">التقنية</p>
        </div>

        <div className="w-1/2 text-center mb-6">
          <div className="bg-white mb-4 inline-block p-8 rounded-full">
            <svg
              viewBox="0 0 62 58"
              className="hobby-icon fa-3x stroke-current text-indigo-500"
            >
              <g
                strokeWidth="1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g transform="translate(1.000000, 1.000000)" strokeWidth="2">
                  <path d="M52,8 L60,8 L60,52 L40,52 C34,52 30,54 30,55.8"></path>
                  <path d="M30,12 C30,8.4 34,0 42.6,0 L52,0 L52,46 L41.9,46 C35.8,46 30,50.2 30,56"></path>
                  <path d="M30,12 C30,10 26,8 16,8 L0,8 L0,52 L20,52 C26,52 30,54 30,55.8 L30,12 Z"></path>
                </g>
              </g>
            </svg>
          </div>

          <p className="text-sm font-bold m-0">الثقافة</p>
        </div>

        <div className="w-1/2  text-center mb-6">
          <div className="bg-white mb-4 inline-block p-8 rounded-full">
            <svg
              viewBox="0 0 68 68"
              className="hobby-icon fa-3x stroke-current text-indigo-500"
            >
              <g
                fill="none"
                strokeLinecap="round"
                strokeWidth="2"
                strokeLinejoin="round"
              >
                <circle cx="31" cy="19.994" r="2"></circle>
                <circle cx="35" cy="31.994" r="2"></circle>
                <circle cx="22" cy="47.994" r="2"></circle>
                <circle cx="38" cy="50.994" r="4"></circle>
                <path d="M47.962 40l-7.439-14.95A5 5 0 0 1 40 22.819V1.994H24V22.82a5 5 0 0 1-.523 2.227L16.04 40"></path>
                <path d="M53.064 50.251L47.962 40H16.04l-5.1 10.254a7.943 7.943 0 0 0-.844 4.98 8.184 8.184 0 0 0 8.17 6.764h27.443a8.2 8.2 0 0 0 8.271-7.44 7.936 7.936 0 0 0-.916-4.307z"></path>
                <path d="M21 1.994h22"></path>
              </g>
            </svg>
          </div>

          <p className="text-sm font-bold m-0">العلم</p>
        </div>
      </div>
    </div>
  </section>
)

export default Hobbies
