import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const About = ({ file }) => (
  <section className="container pt-16 pb-8 px-3 mx-auto max-w-3xl">
    <div className="flex justify-center">
      <div className="flex flex-col">
        <GatsbyImage
          image={file.childImageSharp.gatsbyImageData}
          className="rounded-full w-32 mx-auto"
          alt="صورتي الشخصية"
        />

        <h1 className="mr-4 text-3xl text-gray-700 font-normal mt-4">
          واثق <span className="font-extrabold text-black">الشويطر</span>
        </h1>
      </div>
    </div>

    <div className="post-content text-gray-700">
      <p>
        مطور ويب شغوف بحل المشاكل التقنية وتنفيذ الأفكار الإبداعية. متخصص في لغة
        PHP بإطار عمل Laravel وما حولها من تقنيات. يمكنك معرفة المزيد عني في{' '}
        <a href="assets/Watheq Alshowaiter CV 20240101.pdf" target="_blank" rel="noreferrer ">
          سيرتي المهنية
        </a>
        .
      </p>

      <p>
        كما أنني أحب التدوين حيث يمكنك قراءة{' '}
        <Link href="/blog">تدويناتي هنا</Link>.
      </p>

      <p>
        وقد ترجمت العديد من المقالات التقنية حيث ترجمت لصالح{' '}
        <a href="https://academy.hsoub.com/profile/73703-%D9%88%D8%A7%D8%AB%D9%82-%D8%A7%D9%84%D8%B4%D9%88%D9%8A%D8%B7%D8%B1/content/?type=cms_records8&">
          أكاديمية حسوب
        </a>{' '}
        وترجمت{' '}
        <a href="https://rtlstyling.com/posts/rtl-styling-ar/">
          دليل تنسيقات CSS من اليسار إلى اليمين
        </a>{' '}
        وغيرها.
      </p>

      <p>
        أحب كل ما يتعلق بتقاطعات اللغة والتقنية والعلم والأدب. مهتم بالمشاريع
        التقنية العربية وتعريب العلوم.
      </p>
      <p>يمكنك التواصل معي عبر هذه الشبكات الاجتماعية.</p>

      <div className="flex justify-center my-8">
        <div className="w-full md:w-10/12 flex justify-between flex-wrap">
          <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
            <a href="https://github.com/WatheqAlshowaiter">
              <FontAwesomeIcon
                icon={['fab', 'github']}
                className="text-indigo-500 mr-1"
              />{' '}
              GitHub
            </a>
          </p>

          <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
            <a href="https://twitter.com/watheq_show">
              <FontAwesomeIcon
                icon={['fab', 'twitter']}
                className="text-indigo-500 mr-1"
              />{' '}
              Twitter
            </a>
          </p>

          {/**
        <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
          <a href="https://instagram.com/guillaumebriday">
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              className="text-indigo-500 mr-1"
            />{' '}
            Instagram
          </a>
        </p>
        */}

          <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
            <a href="https://www.youtube.com/channel/UC-iPcPdHqihpNpOwnHCD-mg">
              <FontAwesomeIcon
                icon={['fab', 'youtube']}
                className="text-indigo-500 mr-1"
              />{' '}
              YouTube
            </a>
          </p>

          <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
            <a href="https://www.linkedin.com/in/watheq-show">
              <FontAwesomeIcon
                icon={['fab', 'linkedin']}
                className="text-indigo-500 mr-1"
              />{' '}
              Linkedin
            </a>
          </p>

          <p className="w-1/2 md:w-auto text-center sm:mt-0 mt-4">
            <a href="https://www.facebook.com/watheq.show/">
              <FontAwesomeIcon
                icon={['fab', 'facebook']}
                className="text-indigo-500 mr-1"
              />{' '}
              facebook
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default About
